export const API = process.env.REACT_APP_API;
export const SKey = process.env.REACT_APP_SKey;
export const OCP = process.env.REACT_APP_OCP;

/* EndPoints */
export const GetCities = "/api/UI/GetCities";

export const GetLanguages = "/api/UI/GetLanguages";

export const GetMainPage = "/api/UI/GetMainPage";
export const GetMainPagePreview = "/api/UI/GetMainPagePreview";

export const GetGeneralContent = "/api/UI/GetGeneralContent";
export const GetGeneralContentPreview = "/api/UI/GetGeneralContentPreview";

export const GetCampaignList = "/api/UI/GetCampaignList";
export const GetCampaignDetail = "/api/UI/GetCampaignDetail";
export const GetCampaignDetailPreview = "/api/UI/GetCampaignDetailPreview";

export const GetFooterContent = "/api/UI/GetFooterContent";

export const GetNewsList = "/api/UI/GetNewsList";
export const GetNewsDetail = "/api/UI/GetNewsDetail";
export const GetNewsDetailPreview = "/api/UI/GetNewsDetailPreview";

export const GetSocialMedia = "/api/UI/GetSocialMedia";

export const GetPriceList = "/api/UI/GetPriceList";

export const GetDealerList = "/api/UI/GetDealerList";

export const GetSearchResult = "/api/UI/GetSearchResult";

export const GetRecall = "/api/UI/GetRecall";

export const GetSubNavigationPages = "/api/UI/GetSubNavigationPages";

export const GetSiteScript = "/api/UI/GetSiteScript";

export const GetBrochure = "/api/UI/GetBrochure";
export const GetBrochurePreview = "/api/UI/GetBrochurePreview";

export const Headers = {
  "Content-Ocp-Apim-Subscription-Key": SKey,
  "Ocp-Apim-Trace": OCP,
  "access-control-allow-origin": "*",
};


