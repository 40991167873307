import React from "react";
import languageJson from "../../language.json";
import { Helmet } from "react-helmet";
import getLanguage from "../getLanguage/getLanguage";

export default function Title() {
  getLanguage();
  const lng = languageJson[localStorage.lang];

  return (
    <>
      <Helmet>
        <title>
          {lng != null
            ? lng.allsite.title[61]
            : "Mercedes-Benz Türk'e Hoş geldiniz!"}
        </title>
      </Helmet>
    </>
  );
}
