import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import Cookie from "universal-cookie";
import importedComponent from "react-imported-component";
import ReactPixel from "react-facebook-pixel";
import Main from "./Container/Main/Main";
import { Grid } from "react-loader-spinner";
import languageJson from "./language.json";
import TagManager from "react-gtm-module";

/* Styles */
import "./App.scss";
import "animate.css";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

/* Modules */
const Header = importedComponent(() => import("./Components/Header/Header"));
const Footer = importedComponent(() => import("./Components/Footer/Footer"));

const lng = languageJson[localStorage.lang];
let updatePage = false;

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      LoaderDetails: false,
      GetLanguageState: null,
      updatePage: false,
    };
  }
  pathControl() {
    const element = document.getElementsByTagName("body")[0];

    if (this.props.location.pathname == "/" + lng.mainurl.title[5]) {
      element.classList.add("Map");
    } else {
      element.classList.remove("Map");
    }
  }

  faPixelFirstVersion() {
    ReactPixel.init("705117961454574", options);
    ReactPixel.pageView(); // For tracking page view
  }

  faPixelSecondVersion() {
    ReactPixel.init("705117961454574", options);
    ReactPixel.pageView(); // For tracking page view
  }

  checkFbPixel() {
    const FbPixel = localStorage.getItem("fb");
    if (FbPixel === "true") {
      ReactPixel.init('705117961454574', options);
      ReactPixel.pageView(); // For tracking page view
    }
  }
  checkGA() {
    if (localStorage.lang === undefined) {
      localStorage.lang = "tr";
      localStorage.langid = "20dd5d1f-3c87-48a5-8ff5-bcefb810bcc2";
    }
    if (localStorage.ga === "true") {
      const TRACKING_ID = 'G-XDB4EFBJQM';  //DC-10292080
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send("pageview");
    } else {
      const cookies = new Cookie();
      cookies.remove("_ga", { path: "/" });
      cookies.remove("_gid", { path: "/" });
    }
  }
  checkGtag() {
    const gtagValue = localStorage.getItem("gtag")
    if (gtagValue === "true") {
      const tagManagerArgs = {
        gtmId: 'GTM-K3SW4PX'
      }
      TagManager.initialize(tagManagerArgs)
    }
  }

  async getPosition() {
    await navigator.geolocation.getCurrentPosition(
      position => {
        localStorage.setItem("userLocation", JSON.stringify(
          {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        ));
      },
      err => {
        localStorage.setItem("userLocation", JSON.stringify(
          {
            latitude: null,
            longitude: null
          }
        ));
        return;
      }
    );
  }
  componentDidMount = async () => {
    this.checkFbPixel();
    this.checkGA();
    this.checkGtag();
    this.getPosition();
  };

  render() {
    return (
      <div className="App">
        <div>
          <Helmet>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <Header />
          <Main />
          <Footer />
        </div>
        {updatePage === true ? <Grid /> : ""}
      </div>
    );
  }
}

export default withRouter((props) => <App {...props} />);
