import React from "react";
import ReactDOM from "react-dom";
import { ThroughProvider } from "react-through";

import "./index.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import Title from "./Components/Title/Title";

ReactDOM.render(<Title></Title>, document.getElementById("title"));

ReactDOM.render(
  <BrowserRouter>
    <ThroughProvider>
      <App />
    </ThroughProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
