import axios from "axios";
import { API, GetLanguages, SKey } from "../Services/config";

export async function GetLanguageF() {
  let a = await axios
    .get(`${API}${GetLanguages}`, {
      headers: {
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
        "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
        "Content-Type": "application/json; charset=utf-8",
        "Ocp-Apim-Subscription-Key": SKey,
        "Ocp-Apim-Trace": "true",
        "X-Frame-Options": "Deny",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains"
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) { });

  return a;
}
